// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import React, { MutableRefObject, forwardRef, useReducer, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Script from 'next/script';
import { getMultipleCollections, useBaseFrameProducts } from '@services/shopify';
import { VerticalCard } from '@components/card';
import { capitalize, getBaseName } from '@utils/strings';
import { TypographyButton } from '@components/typography';
import { VTO } from '@components/collections';
import { FRAME_COLORS, VTO_SRC } from '@constants';
import { BaseFrameState, BaseFrameStateDispatch, baseFrameReducer } from '@utils/hooks/useBaseFrame';
import { NormalizedProduct } from '@ts/product';
import { fetchContentful } from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers';
import { DemoListFields } from '@ts/index';
import { Chevron } from '@components/icons';
import { Loading, Tabs, Modal, InteractiveCard, Flex } from '@components/common'
import ComponentHeader from '../ComponentHeader';
import styles from './TabCarousel.module.scss';

type TabCarouselProps = {
	data: NormalizedProduct[];
	demoLists: Record<string, DemoListFields>
	title: string;
	subtitle: string;
	FitMix: any;
	activeTab: 'Eyeglasses' | 'Sunglasses'
};

type TabCarouselContentProps = {
	data: NormalizedProduct[];
	demoLists: Record<string, DemoListFields>;
	setVtoState: React.Dispatch<BaseFrameStateDispatch>;
	frameType: 'eyeglasses' | 'sunglasses';
}

// Wrap the whole thing in an HOC to handle the data fetching, otherwise inital VTO Reducer state is wonky
const TabCarousel = (props) => {
	const [activeTab, setActiveTab] = useState('Eyeglasses')
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);

	// we'll need to pass this in, leaving hard-coded for now until we've run the test
	const { data, isLoading } = useBaseFrameProducts();

	const { data: demoListData, isLoading: demoListLoading, isError: demoListError } = useQuery(['demoList'], async () => {

		const initialData = await Promise.allSettled([
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'women',
				'limit': 1,
				'include': 5,
			}),
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'men',
				'limit': 1,
				'include': 5,
			}),
			fetchContentful({
				'content_type': 'demoList',
				'fields.demo': 'kids',
				'limit': 1,
				'include': 5,
			}),
		])

		const [womenList, menList, kidsList] = (initialData as PromiseFulfilledResult<any>[]).map(result => result.status === 'fulfilled' ? result.value : null).filter(Boolean)


		const normal = await Promise.allSettled([
			normalizeContentfulEntry(womenList),
			normalizeContentfulEntry(menList),
			normalizeContentfulEntry(kidsList),
		])

		const [normalizedWomen, normalizedMen, normalizedKids] = (normal as PromiseFulfilledResult<any>[]).map(result => result.status === 'fulfilled' ? result.value : null).filter(Boolean)

		return {
			women: normalizedWomen,
			men: normalizedMen,
			kids: normalizedKids,
		}
	})

	return <>
		<Modal>
			<Tabs data-tab-carousel value={activeTab} onValueChange={value => setActiveTab(value)} activationMode='manual' className={styles.container}>
				<ComponentHeader className={styles.homepagePadding} title={props.title} subtitle={props.subtitle}>
					<Tabs.List>
						<Tabs.Trigger value='Eyeglasses' data-tab-carousel-tab={'eyeglasses'}>
							<TypographyButton>Eyeglasses</TypographyButton>
						</Tabs.Trigger>
						<Tabs.Trigger value='Sunglasses' style={{ margin: 0 }} data-tab-carousel-tab={'sunglasses'}>
							<TypographyButton>Sunglasses</TypographyButton>
						</Tabs.Trigger>
					</Tabs.List>
				</ComponentHeader>
				{isLoading || demoListLoading ? <div className={styles.loadingWrapper}>
					<Loading />
				</div> :
					<DataFetching data={data?.slice(0, 4)} demoLists={demoListData} activeTab={activeTab} FitMix={FitMix} {...props} />
				}
			</Tabs>
		</Modal>
		<Script
			src={VTO_SRC}
			strategy='lazyOnload'
			type='text/javascript'
			onLoad={() => {
				setFitMix(window.FitMix);
			}}
		></Script>
	</>

}

// This is the actual component that will be rendered
const DataFetching = ({ data, demoLists, FitMix, activeTab }: TabCarouselProps) => {

	const ref = useRef<HTMLDivElement>(null);

	const initialVtoState: BaseFrameState = {
		product: data[0],
		variant: data[0].variants[0],
		frame: data[0].variants[0].option as FRAME_COLORS,
		lens: null,
		image: data[0].variants[0].image,
	};

	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);

	return (
		<>
			<Tabs.Content value='Eyeglasses' removeDefaultStyles>
				<TabCarouselContent data={data} demoLists={demoLists} setVtoState={setVtoState} frameType='eyeglasses' ref={ref} />
			</Tabs.Content>
			<Tabs.Content value='Sunglasses' removeDefaultStyles>
				<TabCarouselContent data={data} demoLists={demoLists} setVtoState={setVtoState} frameType='sunglasses' ref={ref} />
			</Tabs.Content>
			<Modal.Content
				removePadding
				customClose
				onCloseAutoFocus={e => e.preventDefault()}
				style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
			>
				<VTO FM={FitMix} state={vtoState} setState={setVtoState} products={data} pathInfo={`${activeTab.toLowerCase()}/all`} />
			</Modal.Content>
		</>
	);
};

// Content for each Tab is identical on a component level except for sunglass/eyeglass differences, so using one component keeps this file smaller
const TabCarouselContent = forwardRef<HTMLDivElement, TabCarouselContentProps>(({ data, setVtoState, demoLists, frameType }, ref) => {

	const sunglasses = frameType === 'sunglasses';

	const handleClick = (direction) => {
		if (direction === 'left') {
			(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: -180, behavior: 'smooth' });
		} else {
			(ref as MutableRefObject<HTMLDivElement>).current.scrollBy({ left: 180, behavior: 'smooth' });
		}
	}

	return (
		<>
			<div ref={ref} className={styles.content} data-tab-carousel-content={frameType}>
				{data.map(p => (
					<VerticalCard
						key={p.handle}
						product={p}
						variant={p.variants[0]}
						showTags={false}
						primaryAction='view'
						secondaryAction={sunglasses ? null : 'vto'}
						buttonGroupType='stacked'
						showVariantControls
						showLensController={sunglasses}
						label={`Explore The ${getBaseName(p.name, false)}`}
						dispatch={sunglasses ? null : setVtoState}
						data-tab-carousel-card={frameType}
						dataTags={{
							'button': {
								[`data-tab-carousel-card-${frameType}-cta`]: getBaseName(p.name),
							},
						}}
					/>
				))}

			</div>
			<Flex gap={3} justify='end' className={styles.buttonContainer}>
				<div className={styles.button} onClick={() => handleClick('left')}>
					<Chevron direction='left' />
				</div>
				<div className={styles.button} onClick={() => handleClick('right')}>
					<Chevron direction='right' />
				</div>
			</Flex>
			<div className={styles.cards} data-tab-carousel-ctas={frameType}>
				{Object.entries(demoLists).map(([key, value]) => (
					<InteractiveCard key={key}
						background={value[sunglasses ? 'sunglassesHero' : 'hero'].imageMobile.url}
						title=''
						shouldExpand={false}
						size='micro'
						data-tab-carousel-cta={key}
						button1={{
							label: `Shop ${capitalize(key)}`,
							link: `${frameType}/${key.toLowerCase()}`,
							color: 'transparent-light',
						}
						} />
				))
				}
			</div>
		</>
	)
})

TabCarouselContent.displayName = 'TabCarouselContent';

export default TabCarousel;
