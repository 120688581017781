import { Dispatch } from 'react';
import { BaseFrameState, BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import { Img } from '@components/common';
import { Title } from '@components/typography';
import { getVariantByOption } from '@utils/shopify';
import { NormalizedProduct } from '@ts/product';
import styles from './VTO.module.scss';

type VTOProductProps = {
	product: NormalizedProduct;
	active: boolean;
	vtoState: BaseFrameState;
	setVtoState: Dispatch<BaseFrameStateDispatch>;
};

const VTOProduct = ({ product, active, vtoState, setVtoState }: VTOProductProps) => {
	const foundVariant = getVariantByOption(product, vtoState.variant.option);

	return (
		<div
			role='button'
			tabIndex={0}
			className={`${styles[`select-frame__cell`]} ${active ? `${styles.active}` : ''}`}
			onKeyDown={() => {
				setVtoState({
					type: 'product',
					option: { product, variantIdentifier: foundVariant.option },
				});
			}}
			onClick={() => {
				setVtoState({
					type: 'product',
					option: { product, variantIdentifier: foundVariant.option },
				});
			}}
		>
			<Img
				needsPlaceholder
				src={foundVariant.image.url}
				alt={product.name}
				width={foundVariant.image.width}
				height={foundVariant.image.height}
				data-frame={product.name}
				className={product.name}
			/>
			<Title>{product.name}</Title>
		</div>
	);
};

export default VTOProduct;
